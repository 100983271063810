var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-overlay',{attrs:{"show":_vm.show}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"3"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Número de Poste ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Número de Poste","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.luminaria.numeroPoste),callback:function ($$v) {_vm.$set(_vm.luminaria, "numeroPoste", $$v)},expression:"luminaria.numeroPoste"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"3"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Clase de Lámpara ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Clase de Lámpara","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"label":"nombre","options":_vm.modelos},model:{value:(_vm.luminaria.modeloLampara),callback:function ($$v) {_vm.$set(_vm.luminaria, "modeloLampara", $$v)},expression:"luminaria.modeloLampara"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"3"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Modelo de Lámpara ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Modelo de Lámpara","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"label":"nombre","options":_vm.modelosLuminaria},model:{value:(_vm.luminaria.modelo),callback:function ($$v) {_vm.$set(_vm.luminaria, "modelo", $$v)},expression:"luminaria.modelo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"3"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Potencia ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Potencia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false : null,"label":"nombre","value":"nombre","options":_vm.potencias},model:{value:(_vm.luminaria.potencia),callback:function ($$v) {_vm.$set(_vm.luminaria, "potencia", $$v)},expression:"luminaria.potencia"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Tipo ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"label":"nombre","options":_vm.tipos},model:{value:(_vm.luminaria.tipoLampara),callback:function ($$v) {_vm.$set(_vm.luminaria, "tipoLampara", $$v)},expression:"luminaria.tipoLampara"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"8"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Falla ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Falla","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"label":"nombre","multiple":"","options":_vm.fallas},model:{value:(_vm.luminaria.fallas),callback:function ($$v) {_vm.$set(_vm.luminaria, "fallas", $$v)},expression:"luminaria.fallas"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"3"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Grupo ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Grupo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"label":"nombre","options":_vm.grupos},model:{value:(_vm.luminaria.grupo),callback:function ($$v) {_vm.$set(_vm.luminaria, "grupo", $$v)},expression:"luminaria.grupo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"3"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Antena ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Antena","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"label":"nombre","options":_vm.antena},model:{value:(_vm.luminaria.antena),callback:function ($$v) {_vm.$set(_vm.luminaria, "antena", $$v)},expression:"luminaria.antena"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"3"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Serie de Lámpara ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Serie de Lámpara","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.luminaria.codigo),callback:function ($$v) {_vm.$set(_vm.luminaria, "codigo", $$v)},expression:"luminaria.codigo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"3"}},[_c('h5',{staticClass:"text-primary"},[_vm._v(" Nido ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Nido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"label":"nombre","options":_vm.connido},model:{value:(_vm.luminaria.nido),callback:function ($$v) {_vm.$set(_vm.luminaria, "nido", $$v)},expression:"luminaria.nido"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Observaciones","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('h6',{staticClass:"text-primary"},[_vm._v(" Observaciones ")]),_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"rows":"2","no-resize":""},model:{value:(_vm.luminaria.observaciones),callback:function ($$v) {_vm.$set(_vm.luminaria, "observaciones", $$v)},expression:"luminaria.observaciones"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"7"}},[_c('h6',{staticClass:"text-primary"},[_vm._v(" Fotografía de Recepción de Luminaria ")])]),_c('b-col',{staticStyle:{"margin-top":"-10px"},attrs:{"cols":"5"}},[_c('b-button',{attrs:{"block":"","variant":"primary"},on:{"click":function($event){return _vm.agregarFoto($event)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Agregar Foto")])],1)],1),_c('b-col',{staticStyle:{"justify-content":"center","display":"flex"},attrs:{"cols":"12"}},[_c('b-form-group',[_c('table',_vm._l((_vm.fotosIngresadas),function(foto,index){return _c('tr',{key:index},[_c('td',{staticStyle:{"width":"500px"}},[_c('Imagen',{key:'recepcion-' + index,attrs:{"leyenda":'recepcion-' + index},on:{"cargar-file":function($event){return _vm.imagenRecepcion(index, $event)}}})],1),_c('td',{staticStyle:{"width":"300px"}},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.eliminarFoto()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Eliminar")])],1)],1)])}),0)])],1)],1),_c('hr'),_c('b-row',[_c('b-col',{staticStyle:{"display":"flex","justify-content":"end"},attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.validationForm}},[_vm._v(" Guardar Recepción ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }