<template>
  <div>
    <b-card>
      <validation-observer ref="simpleRules">
        <b-form>
          <b-overlay
            :show="show"
          >
            <b-row>
              <b-col
                cols="12"
                sm="3"
              >
                <h5 class="text-primary">
                  Número de Poste
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Número de Poste"
                    rules="required"
                  >
                    <b-form-input
                      v-model="luminaria.numeroPoste"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="3"
              >
                <h5 class="text-primary">
                  Clase de Lámpara
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Clase de Lámpara"
                    rules="required"
                  >
                    <v-select
                      v-model="luminaria.modeloLampara"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="modelos"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="3"
              >
                <h5 class="text-primary">
                  Modelo de Lámpara
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Modelo de Lámpara"
                    rules="required"
                  >
                    <v-select
                      v-model="luminaria.modelo"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="modelosLuminaria"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="3"
              >
                <h5 class="text-primary">
                  Potencia
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Potencia"
                    rules="required"
                  >
                    <v-select
                      v-model="luminaria.potencia"
                      :state="errors.length > 0 ? false : null"
                      label="nombre"
                      value="nombre"
                      :options="potencias"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="4"
              >
                <h5 class="text-primary">
                  Tipo
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Tipo"
                    rules="required"
                  >
                    <v-select
                      v-model="luminaria.tipoLampara"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="tipos"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="8"
              >
                <h5 class="text-primary">
                  Falla
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Falla"
                    rules="required"
                  >
                    <v-select
                      v-model="luminaria.fallas"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      multiple
                      :options="fallas"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="3"
              >
                <h5 class="text-primary">
                  Grupo
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Grupo"
                    rules="required"
                  >
                    <v-select
                      v-model="luminaria.grupo"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="grupos"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="3"
              >
                <h5 class="text-primary">
                  Antena
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Antena"
                    rules="required"
                  >
                    <v-select
                      v-model="luminaria.antena"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="antena"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="3"
              >
                <h5 class="text-primary">
                  Serie de Lámpara
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Serie de Lámpara"
                    rules="required"
                  >
                    <b-form-input
                      v-model="luminaria.codigo"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="3"
              >
                <h5 class="text-primary">
                  Nido
                </h5>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Nido"
                    rules="required"
                  >
                    <v-select
                      v-model="luminaria.nido"
                      :state="errors.length > 0 ? false:null"
                      label="nombre"
                      :options="connido"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="12"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Observaciones"
                    rules="required"
                  >
                    <h6 class="text-primary">
                      Observaciones
                    </h6>
                    <b-form-textarea
                      v-model="luminaria.observaciones"
                      :state="errors.length > 0 ? false:null"
                      rows="2"
                      no-resize
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col cols="7">
                <h6 class="text-primary">
                  Fotografía de Recepción de Luminaria
                </h6>
              </b-col>
              <b-col
                cols="5"
                style="margin-top: -10px"
              >
                <b-button
                  block
                  variant="primary"
                  @click="agregarFoto($event)"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Agregar Foto</span>
                </b-button>
              </b-col>
              <b-col
                style="justify-content: center; display: flex"
                cols="12"
              >
                <b-form-group>
                  <table>
                    <tr
                      v-for="(foto, index) in fotosIngresadas"
                      :key="index"
                    >
                      <td style="width: 500px">
                        <Imagen
                          :key="'recepcion-' + index"
                          :leyenda="'recepcion-' + index"
                          @cargar-file="imagenRecepcion(index, $event)"
                        />
                      </td>
                      <td style="width: 300px">
                        <b-button
                          variant="danger"
                          @click="eliminarFoto()"
                        >
                          <feather-icon
                            icon="TrashIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">Eliminar</span>
                        </b-button>
                      </td>
                    </tr>
                  </table>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col
                style="display: flex; justify-content: end"
                cols="12"
              >
                <b-button
                  variant="primary"
                  @click="validationForm"
                >
                  Guardar Recepción
                </b-button>
              </b-col>
            </b-row>
          </b-overlay>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BFormTextarea, BCol, BRow, BForm, BFormGroup, BFormInput, BOverlay, BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { getOpciones } from '@/utils/mantenimiento/opcionesGenericas'
import { getlistado } from '@/utils/catalogos'
import { required } from '@core/utils/validations/validations'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import {
  creacionLuminaria,
  getListadoFallasLuminaria,
  updateCreateLuminaria,
} from '@/utils/mantenimiento/bodega/bodegaUtils'
import { saveImagen } from '@/utils/imagenes'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getPoste } from '@/utils/postes'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { findLuminariasLab } from '@/utils/laboratorio/luminarias'

export default {
  components: {
    Imagen,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormTextarea,
    BButton,
    BCol,
    BRow,
    BForm,
    BCard,
    vSelect,
    BOverlay,
    BFormInput,
  },
  data() {
    return {
      required,
      show: false,
      usuario: JSON.parse(localStorage.getItem('userData')),
      luminaria: {
        idLuminaria: '',
        fechaCreacion: new Date(),
        numeroPoste: null,
        poste: {},
        modeloLampara: null,
        modelo: null,
        tipoLampara: '',
        grupo: null,
        potencia: '',
        fallas: '',
        antena: null,
        codigo: null,
        nido: null,
        observaciones: null,
        estado: 'INGRESADA',
        tracking: [
          {
            estado: 'INGRESADA',
            horaFecha: new Date(),
          },
        ],
        seguimiento: [],
        usuarioCreacion: JSON.parse(localStorage.getItem('userData')),
        companyId: JSON.parse(localStorage.getItem('userData')).idCompany,
      },
      posteId: router.currentRoute.params.poste,
      scannedData: '',
      fotosIngresadas: [''],
      connido: [],
      grupos: [],
      modelos: [],
      modelosLuminaria: [],
      fallas: [],
      potencias: [],
      antena: [],
      tipos: [],
    }
  },
  async created() {
    const { scannedData } = this.$route.query
    const params = new URLSearchParams(window.location.search)
    const serieEscaneada = params.get('scannedData')
    // ESTE IF TOMA EL CÓDIGO Y MANDA A BUSCAR Y UTILIZA EL ULTIMO OBJETO DEL ARRAY Y APLICA ESOS ÚLTIMOS CAMBIOS
    if (serieEscaneada) {
      const decodedData = JSON.parse(decodeURIComponent(serieEscaneada))
      const { codigo } = decodedData
      try {
        if (codigo) {
          // eslint-disable-next-line no-shadow
          const filter = {
            where: {
              companyId: this.usuario.company.id,
              serie: codigo,
            },
          }
          let luminariaEncontrada = await findLuminariasLab(filter)
          if (luminariaEncontrada.length > 0) {
            // eslint-disable-next-line prefer-destructuring
            luminariaEncontrada = luminariaEncontrada[0]
            this.luminaria.tipoLampara = {
              nombre: luminariaEncontrada.tipo,
            }
            this.luminaria.modeloLampara = luminariaEncontrada.clase
            this.luminaria.modelo = luminariaEncontrada.tipoLampara
            this.luminaria.potencia = luminariaEncontrada.potencia
            this.luminaria.idLuminaria = luminariaEncontrada.id
          }
        } else {
          this.luminaria.numeroPoste = 'APVN0000'
          this.luminaria.grupo = {
            active: true,
            id: 1,
            nombre: 'G1',
            user: 'ADMIN',
          }
          this.luminaria.potencia = {
            nombre: '30',
            valor: 30,
          }
        }
      } catch (error) {
        console.error('Error al procesar scannedData:', error)
      }
    }
    if (scannedData) {
      try {
        const decodedData = JSON.parse(scannedData)
        this.luminaria.numeroPoste = decodedData.numeroPoste
        this.luminaria.codigo = decodedData.codigo
        await this.$nextTick()
      } catch (error) {
        console.error('Error parsing JSON:', error)
      }
    } else if (this.$route.params.poste) {
      // Si no hay datos escaneados pero hay un poste, obtener datos del poste
      this.posteId = this.$route.params.poste
      this.poste = await getPoste(this.posteId)
      if (this.poste) {
        this.luminaria.numeroPoste = this.poste.numeroPoste
        this.luminaria.modeloLampara = this.poste.modeloLampara
        this.luminaria.grupo = this.poste.objGrupo
        this.luminaria.codigo = this.poste.serieLampara
        this.luminaria.potencia = { nombre: this.poste.potencia }
      }
    } else {
      // Resto de tu lógica existente para el caso en que no hay datos escaneados ni poste
      this.luminaria.numeroPoste = 'APVN0000'
      this.luminaria.grupo = {
        active: true,
        id: 1,
        nombre: 'G1',
        user: 'ADMIN',
      }
      this.luminaria.potencia = {
        nombre: '30',
        valor: 30,
      }
    }
    this.modelos = await getlistado(3, 'Modelos', false, this.usuario)
    this.connido = getOpciones(6)
    this.grupos = await getlistado(12, 'Grupos', false, this.usuario)
    this.fallas = await getlistado(21, 'Fallas Lámparas Bodega', false, this.usuario)
    this.potencias = await getlistado(25, 'Potencias Lámparas', false, this.usuario)
    this.antena = getListadoFallasLuminaria(3)
    this.tipos = await getlistado(34, 'Tipo de Lámparas de Bodega', false, this.usuario)
    this.modelosLuminaria = await getlistado(9, 'Tipo de Lámparas', false, this.usuario)
  },
  methods: {
    async guardarRecepcion() {
      this.show = true
      try {
        if (this.fotosIngresadas.length === 0 || !this.fotosIngresadas[0].name) {
          mensajeError('Debe Ingresar Mínimo una Fotografía de la Recepción de la Luminaria')
          return
        }

        const valorPotenciaTexto = this.luminaria.potencia

        const potenciaObjeto = {
          nombre: valorPotenciaTexto,
        }

        this.luminaria.potencia = potenciaObjeto.nombre

        this.luminaria.usuarioCreacion = {
          id: this.usuario.id,
          nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
          email: this.usuario.email,
          telefono: '',
        }

        const body = { ...this.luminaria }

        if (this.scannedData) {
          body.poste = {
            municipio: 15,
            numeroPoste: this.luminaria.numeroPoste,
            serieLampara: this.luminaria.codigo,
          }
        } else if (!this.poste) {
          body.poste = {
            municipio: 15,
            numeroPoste: 'APVN0000',
            serieLampara: this.luminaria.codigo,
          }
        }

        const item = await updateCreateLuminaria(body, 2)
        if (!item) {
          mensajeError('Inconvenientes almacenando la recepción de luminaria!, intente nuevamente!')
          this.show = false
          return
        }

        const urlBucket = `falla-lampara/${item.id}`
        const isMobile = window.screen.width <= 760

        const primerSeguimiento = {
          id: 1,
          estado: 'Ingreso de Luminaria',
          fechaHora: new Date(),
          tracking: [],
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const foto of this.fotosIngresadas) {
          if (foto !== '') {
            // eslint-disable-next-line no-await-in-loop
            const imagen = await saveImagen(urlBucket, this.localization, foto, 'Ingreso de Luminaria', '', this.luminaria.usuarioCreacion, isMobile)
            if (imagen) {
              primerSeguimiento.tracking.push(imagen)
            }
          }
        }
        item.seguimiento.push(primerSeguimiento)
        const resultado = await creacionLuminaria(item.id, this.luminaria, this.usuario, 'INGRESO', this.modelo)
        if (!resultado) {
          mensajeError('Algo ha salido mal, intenta nuevamente!')
          return
        }
        await updateCreateLuminaria(item, 1)
        mensajeInformativo('Guardada', 'Recepción de Luminaria Almacenada Correctamente!')
        this.$router.replace('/mantenimiento-bodega').then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Recepción de Luminaria Almacenada!',
              icon: 'CheckIcon',
              variant: 'success',
              text: 'Recepción de Luminaria correctamente!',
            },
          })
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.show = false
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate()
        .then(success => {
          if (success) {
            this.guardarRecepcion()
            this.$bvModal.hide('modal-1')
          }
        })
    },
    closeModal() {
      this.$bvModal.hide('modal-1')
    },
    agregarFoto() {
      this.fotosIngresadas.push('')
    },
    eliminarFoto(index) {
      if (this.fotosIngresadas.length > 1) {
        this.fotosIngresadas.splice(index, 1)
      }
    },
    imagenRecepcion(index, file) {
      this.$set(this.fotosIngresadas, index, file)
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
